import React, { useState } from "react";
import { navigate } from "gatsby-link";
import Layout from "../../components/Layout";
import { postForm } from "../../helpers/SendFormHelper";

const PurchaseFormPage = () => {
  const [state, setState] = useState({
    isValidated: false,
    name: "",
    phone: "",
    email: "",
    homeOwner: "No",
    homePurpose: "",
    vaLoan: "No",
    vaDisability: "No",
    realtor: "No",
    purchasePrice: "Under $450,000",
    downPaymentSavings: 0,
    maxMonthlyPayment: 0,
    plannedLivingYears: 0,
  });

  const handleChange = (e) => {
    setState({ ...state, [e.target.name]: e.target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const form = e.target;
    postForm(form.getAttribute("name"), state)
      .then(() => navigate(form.getAttribute("action")))
      .catch((error) => alert(error));
  };

  return (
    <Layout>
      <section className="section" style={{ marginTop: "7rem" }}>
        <div className="container">
          <h2 className="title is-2 has-text-centered">Purchase A Home</h2>
        </div>
        <section className="section">
          <div className="container" style={{ maxWidth: 900 }}>
            <form
              name="purchase-request"
              method="post"
              action="/contact/thanks/"
              data-netlify="true"
              data-netlify-honeypot="bot-field"
              onSubmit={handleSubmit}
            >
              {/* The `form-name` hidden field is required to support form submissions without JavaScript */}
              <input type="hidden" name="form-name" value="purchase-request" />
              <div hidden>
                <label>
                  Don’t fill this out:{" "}
                  <input name="bot-field" onChange={handleChange} />
                </label>
              </div>

              <div className="field">
                <label className="label" htmlFor={"name"}>
                  Your name
                </label>
                <div className="control">
                  <input
                    className="input"
                    type={"text"}
                    name={"name"}
                    onChange={handleChange}
                    id={"name"}
                    required={true}
                  />
                </div>
              </div>

              <div className="field">
                <label className="label" htmlFor={"phone"}>
                  Phone
                </label>
                <div className="control">
                  <input
                    className="input"
                    type={"tel"}
                    name={"phone"}
                    onChange={handleChange}
                    id={"phone"}
                    required={true}
                  />
                </div>
              </div>

              <div className="field">
                <label className="label" htmlFor={"email"}>
                  Email
                </label>
                <div className="control">
                  <input
                    className="input"
                    type={"email"}
                    name={"email"}
                    onChange={handleChange}
                    id={"email"}
                    required={true}
                  />
                </div>
              </div>

              <div className="field">
                <label className="label" htmlFor={"ownAHome"}>
                  Do you currently own a home?
                </label>
                <div className="control">
                  <div className="select is-fullwidth">
                    <select
                      className="input"
                      name={"homeOwner"}
                      onChange={handleChange}
                      id={"homeOwner"}
                      required={true}
                    >
                      <option>No</option>
                      <option>Yes</option>
                    </select>
                  </div>
                </div>
              </div>

              <fieldset disabled={state.homeOwner !== "Yes"}>
                <div className="field">
                  <label className="label" htmlFor={"monthlyPayment"}>
                    What is your monthly payment?
                  </label>
                  <div className="control">
                    <input
                      className="input"
                      type="number"
                      name={"monthlyPayment"}
                      onChange={handleChange}
                      id={"monthlyPayment"}
                    />
                  </div>
                </div>

                <div className="field">
                  <label className="label" htmlFor={"homePurpose"}>
                    Is this home for living or investment?
                  </label>
                  <div className="control">
                    <div className="select is-fullwidth">
                      <select
                        className="input"
                        name={"homePurpose"}
                        onChange={handleChange}
                        id={"homePurpose"}
                        required={true}
                      >
                        <option>Living</option>
                        <option>Investment</option>
                      </select>
                    </div>
                  </div>
                </div>
              </fieldset>

              <div className="field">
                <label className="label" htmlFor={"vaLoan"}>
                  Are you eligible for a VA loan?
                </label>
                <div className="control">
                  <div className="select is-fullwidth">
                    <select
                      className="input"
                      name={"vaLoan"}
                      onChange={handleChange}
                      id={"vaLoan"}
                    >
                      <option>No</option>
                      <option>Yes</option>
                      <option>Unsure</option>
                    </select>
                  </div>
                </div>
              </div>

              <fieldset disabled={state.vaLoan !== "Yes"}>
                <div className="field">
                  <label className="label" htmlFor={"vaDisability"}>
                    Do you have a 10% disability rating or higher?
                  </label>
                  <div className="control">
                    <div className="select is-fullwidth">
                      <select
                        className="input"
                        name={"vaDisability"}
                        onChange={handleChange}
                        id={"vaDisability"}
                      >
                        <option>No</option>
                        <option>Yes</option>
                      </select>
                    </div>
                  </div>
                </div>
              </fieldset>

              <div className="field">
                <label className="label" htmlFor={"realtor"}>
                  Do you have a realtor?
                </label>
                <div className="control">
                  <div className="select is-fullwidth">
                    <select
                      className="input"
                      name={"realtor"}
                      onChange={handleChange}
                      id={"realtor"}
                    >
                      <option>No</option>
                      <option>Yes</option>
                    </select>
                  </div>
                </div>
              </div>

              <div className="field">
                <label className="label" htmlFor={"purchasePrice"}>
                  What is your estimated purchase price?
                </label>
                <div className="control">
                  <div className="select is-fullwidth">
                    <select
                      className="input"
                      name={"purchasePrice"}
                      onChange={handleChange}
                      id={"purchasePrice"}
                    >
                      <option>Under $450,000</option>
                      <option>$450,000 - $600,000</option>
                      <option>$600,000 - $750,000</option>
                      <option>$750,000 - $1,000,000</option>
                      <option>Above $1,000,000</option>
                    </select>
                  </div>
                </div>
              </div>

              <div className="field">
                <label className="label" htmlFor={"downPaymentSavings"}>
                  How much do you currently have saved for a down payment?
                </label>
                <div className="control">
                  <input
                    className="input"
                    type="number"
                    name={"downPaymentSavings"}
                    onChange={handleChange}
                    id={"downPaymentSavings"}
                  />
                </div>
              </div>

              <div className="field">
                <label className="label" htmlFor={"maxMonthlyPayment"}>
                  What is the max monthly payment your feel comfortable making?
                </label>
                <div className="control">
                  <input
                    className="input"
                    type="number"
                    name={"maxMonthlyPayment"}
                    onChange={handleChange}
                    id={"maxMonthlyPayment"}
                  />
                </div>
              </div>

              <div className="field">
                <label className="label" htmlFor={"plannedLivingYears"}>
                  How many years do you plan to live in the house?
                </label>
                <div className="control">
                  <input
                    className="input"
                    type="number"
                    name={"plannedLivingYears"}
                    onChange={handleChange}
                    id={"plannedLivingYears"}
                  />
                </div>
              </div>

              <div className="field">
                <button className="button is-link" type="submit">
                  Send
                </button>
              </div>
            </form>
          </div>
        </section>
      </section>
    </Layout>
  );
};

export default PurchaseFormPage;
